import ActivityImport from './admin/views/activity-import/activity-import.js';
import ApplyActivity from './main/views/activities/apply-activity/apply-activity.js';
import AuthError from './shared/components/login/auth-error/auth-error.js';
import ContactSupport from './main/views/general/contact-support/contact-support.js';
import EditActivity from './admin/views/edit-activity/edit-activity.js';
import EditTenant from './admin/views/edit-tenant/edit-tenant.js';
import GetUserDetails from './shared/components/general/get-user-details/get-user-details.js';
import HowItWorks from './shared/views/view-home/view-home.js';
import LoginCollectDetails from './shared/components/login/login-collect-details/login-collect-details.js';
import LoginView from './shared/views/view-login/view-login.js';
import NotFound from './shared/views/errors/view-not-found/view-not-found.js';
import ReviewAdmissionRequirements from './main/views/applications/review-admission-requirements/review-admission-requirements.js';
import ReviewApplication from './main/views/applications/review-application/review-application.js';
import ReviewSubmitOwnRequestApplication from './main/views/applications/review-submit-own-request-application/review-submit-own-request-application.js';
import SkillsWaveRedirect from './shared/views/skillswave-redirect/skillswave-redirect.js';
import SubmitOwnRequest from './main/views/applications/submit-own-request/submit-own-request.js';
import ThankyouSignup from './shared/components/login/thankyou-signup/thankyou-signup.js';
import ViewAccountConnection from './main/views/applications/view-account-connection/view-account-connection.js';
import ViewActivitiesDashboard from './main/views/activities/view-activities-dashboard/view-activities-dashboard.js';
import ViewActivity from './main/views/activities/view-activity/view-activity.js';
import ViewActivityCategory from './main/views/activities/view-activity-category/view-activity-category.js';
import ViewAdmin from './admin/views/view-admin/view-admin.js';
import ViewApplication from './main/views/applications/view-application/view-application.js';
import ViewApplicationConfirmation from './main/views/applications/view-application-confirmation/view-application-confirmation.js';
import ViewApplications from './main/views/applications/view-applications/view-applications.js';
import ViewClientOnboarding from './main/views/client-onboarding/view-client-onboarding/view-client-onboarding.js';
import ViewErrorLoading from './shared/views/errors/view-error-loading/view-error-loading.js';
import ViewLandingPage from './main/views/profile/view-landing-page/view-landing-page.js';
import ViewMagicLink from './shared/views/redirect-magic-link/redirect-magic-link.js';
import ViewNotAllowed from './shared/views/errors/view-not-allowed/view-not-allowed.js';
import ViewOnboarding from './main/views/onboarding/view-onboarding/view-onboarding.js';
import ViewProvider from './main/views/providers/view-provider/view-provider.js';
import ViewReportDashboard from './shared/views/report/view-report-dashboard/view-report-dashboard.js';
import ViewSettings from './settings/views/view-settings/view-settings.js';

const disallowGuest = session => !session.loggedIn || session.isPublicPortalGuest;

const routes = [
  {
    name: 'home',
    pattern: '',
    data: {
      getDynamicView: session => (session.featureEnabled('skillOnboarding') ? ViewLandingPage : HowItWorks),
      layout: 'full-width',
      documentTitleLangKey: 'app-nova.documentTitle',
    },
  },
  {
    name: 'recommendations',
    pattern: 'recommendations',
    data: {
      getDynamicView: session => (session.featureEnabled('skillOnboarding') ? ViewLandingPage : NotFound),
      layout: 'full-width',
      documentTitleLangKey: 'view-landing-page.documentTitle',
    },
  },
  {
    name: 'how-it-works',
    pattern: 'how-it-works',
    data: {
      View: HowItWorks,
      layout: 'full-width',
      documentTitleLangKey: 'app-nova.documentTitle',
    },
  },
  {
    name: 'activities',
    pattern: 'activities',
    data: {
      View: ViewActivitiesDashboard,
      documentTitleLangKey: 'view-activities.documentTitle',
    },
  },
  {
    name: 'activities-by-career',
    pattern: 'activities/skill-stream/career/:careerId',
    data: {
      View: ViewActivityCategory,
      documentTitleLangKey: 'view-activity-category.documentTitle.whileLoading',
    },
  },
  {
    name: 'activities-by-goals',
    pattern: 'activities/skill-stream/interested-goals/:goalId',
    data: {
      View: ViewActivityCategory,
      documentTitleLangKey: 'view-activity-category.documentTitle.whileLoading',
    },
  },
  {
    name: 'activities-by-skill-stream',
    pattern: 'activities/skill-stream/:category',
    data: {
      View: ViewActivityCategory,
      documentTitleLangKey: 'view-activity-category.documentTitle.whileLoading',
    },
  },
  {
    name: 'view-provider',
    pattern: 'providers/:providerId',
    data: {
      View: ViewProvider,
      documentTitleLangKey: 'view-activity-category.documentTitle.whileLoading',
    },
  },
  {
    name: 'add-activity',
    pattern: 'activities/add',
    data: {
      View: EditActivity,
      documentTitleLangKey: 'edit-activity.documentTitle.addActivity',
    },
  },
  {
    name: 'import-activity',
    pattern: 'activities/import',
    data: {
      View: ActivityImport,
      documentTitleLangKey: 'activity-import.documentTitle.stream',
    },
  },
  {
    name: 'edit-activity',
    pattern: 'activities/:id/edit',
    data: {
      View: EditActivity,
      documentTitleLangKey: 'edit-activity.documentTitle',
    },
  },
  {
    name: 'view-activity',
    pattern: 'activities/:id',
    data: {
      View: ViewActivity,
      layout: 'full-width',
      documentTitleLangKey: 'view-activity.documentTitle.whileLoading',
    },
  },
  {
    name: 'magic-link',
    pattern: 'login/magic',
    data: {
      protected: () => false,
      View: ViewMagicLink,
      layout: 'splash',
      documentTitleLangKey: 'login.magiclink.documentTitle',
    },
  },
  {
    name: 'skillswave-redirect',
    pattern: 'skillswave-redirect',
    data: {
      protected: () => false,
      View: SkillsWaveRedirect,
      layout: 'splash',
      documentTitleLangKey: 'redirect.skillswave.documentTitle',
    },
  },
  {
    name: 'apply-activity',
    pattern: 'activities/:id/apply',
    data: {
      View: ApplyActivity,
      documentTitleLangKey: 'apply-activity.documentTitle',
    },
  },
  {
    name: 'review-requirements',
    pattern: 'activities/:original_id/:selected_id/apply/review-requirements',
    data: {
      View: ReviewAdmissionRequirements,
      documentTitleLangKey: 'admission-requirements.review.documentTitle',
    },
  },
  {
    name: 'review-application',
    pattern: 'activities/:original_id/:selected_id/apply/review',
    data: {
      View: ReviewApplication,
      documentTitleLangKey: 'review-application.documentTitle',
    },
  },
  {
    name: 'review-submit-own-request-application',
    pattern: 'activities/apply/review-submit-own-request',
    data: {
      View: ReviewSubmitOwnRequestApplication,
      documentTitleLangKey: 'review-application.documentTitle',
    },
  },
  {
    name: 'redirect-admin',
    pattern: 'admin',
    data: {
      type: 'redirect',
      url: '/admin/activities',
    },
  },
  {
    name: 'view-admin',
    pattern: 'admin/:panel',
    data: {
      View: ViewAdmin,
      layout: 'admin',
      documentTitleLangKey: 'view-admin.documentTitle',
    },
  },
  {
    name: 'add-tenant',
    pattern: 'tenants/add/:type',
    data: {
      View: EditTenant,
      documentTitleLangKey: 'edit-tenant.documentTitle.addTenant',
    },
  },
  {
    name: 'edit-tenant',
    pattern: 'tenants/:id/edit/:panel',
    data: {
      View: EditTenant,
      layout: 'admin',
      documentTitleLangKey: 'edit-tenant.documentTitle',
    },
  },
  {
    name: 'edit-tenant-redirect',
    pattern: 'tenants/:id/edit',
    data: {
      type: 'redirect',
      url: '/tenants/:id/edit/general',
    },
  },
  {
    name: 'analytics',
    pattern: 'analytics',
    data: {
      View: ViewReportDashboard,
      documentTitleLangKey: 'view-report-dashboard.documentTitle',
    },
  },
  {
    name: 'redirect-settings',
    pattern: 'settings',
    data: {
      type: 'redirect',
      url: '/settings/details',
    },
  },
  {
    name: 'settings',
    pattern: 'settings/:panel',
    data: {
      View: ViewSettings,
      layout: 'admin',
      documentTitleLangKey: 'view-settings.documentTitle',
    },
  },
  {
    name: 'view-applications',
    pattern: 'requests',
    data: {
      protected: disallowGuest,
      View: ViewApplications,
      documentTitleLangKey: 'view-applications.documentTitle',
    },
  },
  {
    name: 'submit-own-request',
    pattern: 'requests/submit-own-request',
    data: {
      protected: disallowGuest,
      View: SubmitOwnRequest,
      documentTitleLangKey: 'submit-own-request.documentTitle',
    },
  },
  {
    name: 'review-application',
    pattern: 'requests/submit-own-request/review',
    data: {
      protected: disallowGuest,
      View: ReviewApplication,
      documentTitleLangKey: 'review-application.documentTitle',
    },
  },
  {
    name: 'view-application',
    pattern: 'requests/:uuid',
    data: {
      protected: disallowGuest,
      View: ViewApplication,
      documentTitleLangKey: 'view-application.documentTitle',
    },
  },
  {
    name: 'view-application-account-connection',
    pattern: 'requests/:uuid/account-connection',
    data: {
      protected: disallowGuest,
      View: ViewAccountConnection,
      documentTitleLangKey: 'view-application.documentTitle',
    },
  },
  {
    name: 'contact-support',
    pattern: 'contact-support',
    data: {
      View: ContactSupport,
      documentTitleLangKey: 'contact-support.documentTitle',
    },
  },
  {
    name: 'login',
    pattern: 'login',
    data: {
      protected: () => false,
      View: LoginView,
      layout: 'splash',
      documentTitleLangKey: 'view-login.documentTitle',
    },
  },
  {
    name: 'login-details',
    pattern: 'login-details',
    data: {
      View: LoginCollectDetails,
      layout: 'splash',
      documentTitleLangKey: 'view-login-details.documentTitle',
    },
  },
  { // This is a legacy view that I would like to replace with the above view at some point soon
    name: 'get-user-details',
    pattern: 'login/get-user-details',
    data: {
      View: GetUserDetails,
      layout: 'splash',
      documentTitleLangKey: 'view-login-details.documentTitle',
    },
  },
  {
    name: 'not-found',
    pattern: '*',
    data: {
      protected: () => false,
      View: NotFound,
      documentTitleLangKey: 'view-not-found.documentTitle',
    },
  },
  {
    name: 'error',
    pattern: 'error',
    data: {
      protected: () => false,
      View: ViewErrorLoading,
      layout: 'splash',
      documentTitleLangKey: 'view-error-loading.documentTitle',
    },
  },
  {
    name: 'view-application-confirmation',
    pattern: 'requests/:uuid/confirmation',
    data: {
      View: ViewApplicationConfirmation,
      documentTitleLangKey: 'view-application.documentTitle',
    },
  },
  {
    name: 'thankyou-signup',
    pattern: 'thankyou-signup',
    data: {
      layout: 'full-width',
      View: ThankyouSignup,
      documentTitleLangKey: 'thankyou-page.documentTitle',
    },
  },
  {
    name: 'auth-error',
    pattern: 'auth-error',
    data: {
      protected: () => false,
      layout: 'full-width',
      View: AuthError,
      documentTitleLangKey: '"view-error-loading.documentTitle"',
    },
  },
  {
    name: 'onboarding',
    pattern: 'onboarding',
    data: {
      getDynamicView: session => (session.featureEnabled('skillOnboarding') ? ViewOnboarding : session.hasFeature('readonly') ? HowItWorks : NotFound),
      documentTitleLangKey: 'view-onboarding.documentTitle',
    },
  },
  {
    name: 'client-onboarding',
    pattern: 'client-onboarding',
    data: {
      getDynamicView: session => (session.tenant.hasTag('plgTenant') ? ViewClientOnboarding : NotFound),
      documentTitleLangKey: 'view-client-onboarding.documentTitle',
    },
  },
  {
    name: 'error-not-allowed',
    pattern: '403',
    data: {
      View: ViewNotAllowed,
      documentTitleLangKey: 'view-error-loading.documentTitle',
    },
  },
];

export default routes.map(route => {
  // Set all routed to protected unless explicitly set to false
  if (!route.data.protected) {
    route.data.protected = session => !session.loggedIn;
  }
  return route;
});
