import '../../../components/nova-flow/nova-flow/nova-flow.js';
import '../../../components/nova-flow/nova-flow-step/nova-flow-step.js';

import { html, LitElement } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../../shared/mixins/nova-nav/nova-nav.js';

export default class ViewClientOnboarding extends LocalizeNova(RequesterMixin(NovaNavMixin(LitElement))) {

  static get properties() {
    return {
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  _completedFlow() {
    this.navigate('/');
  }

  render() {
    return html`
      <nova-flow @completed=${this._completedFlow}>
        <nova-flow-step title="Welcome!" step="welcome" ready>
          ${this.localize('view-client-onboarding.welcomeMessage')}
        </nova-flow-step>
        <nova-flow-step title="Thank you!" step="end" ready>
          You are all done!
        </nova-flow-step>
      </nova-flow>
    `;
  }
}

window.customElements.define('view-client-onboarding', ViewClientOnboarding);
