import '@brightspace-ui/core/components/button/button.js';

import { html, LitElement, nothing } from 'lit';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

class NovaFlow extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      _steps: { type: Array },
      _currentStep: { type: Number },
    };
  }

  constructor() {
    super();
    this._steps = [];
    this._currentStep = 0;
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('nova-flow-step-ready', this._stepReady);

    this._steps = Array.from(this.children).map(child => ({
      name: child.getAttribute('step'),
      skippable: child.hasAttribute('skippable'),
      blockBack: child.hasAttribute('block-back'),
      ready: child.hasAttribute('ready'),
    }));
    this._currentStep = 0;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('nova-flow-step-ready');
  }

  _stepReady(e) {
    this._steps = this._steps.map(step => {
      if (step.name === e.detail.step) {
        return { ...step, ready: e.detail.ready };
      }
      return step;
    });
  }

  _goBack() {
    this._currentStep = Math.max(0, this._currentStep - 1);
  }

  _goNext() {
    this._currentStep = Math.min(this._steps.length - 1, this._currentStep + 1);
  }

  _finish() {
    this.dispatchEvent(new CustomEvent('completed'));
  }

  render() {
    return html`
      <div class="progress-bar">
        progress bar: ${this._currentStep + 1} / ${this._steps.length}
      </div>
      <div class="slotted-content">
        ${this._steps.length ? html`
          <slot name="${this._steps[this._currentStep].name}"></slot>
        ` : nothing}
      </div>
      <div class="navigation-buttons">
          ${this._currentStep > 0 && !this._steps[this._currentStep].blockBack ? html`
            <d2l-button @click=${this._goBack}>Previous</d2l-button>
          ` : nothing}
          ${this._currentStep < this._steps.length - 1 ? html`
            <d2l-button primary ?disabled=${!this._steps[this._currentStep].ready} @click=${this._goNext}>Next</d2l-button>
          ` : nothing}
          ${this._currentStep === this._steps.length - 1 ? html`
            <d2l-button primary ?disabled=${!this._steps[this._currentStep].ready} @click=${this._finish}>Finish</d2l-button>
          ` : nothing}
      </div>
    `;
  }

}

window.customElements.define('nova-flow', NovaFlow);
